.gatewaysconfig {
  color: white;
  margin-left: 300px;
	margin-right: 100px;
	padding-top: 50px;
	padding-left: 20px;
	padding-right: 20px;
  flex: 1 0 auto;
}

.configitem {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.configname {
	flex: 1 0 auto;
}

.configvalue {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #684aff;
  background: none;
  color: white;
  text-align: center;
  width: 250px;
}

.configsectiontitle {
  margin-bottom: 40px;
}
