.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.goalitem{
  display: grid;
}
.goaldescription{
  grid-row: 1;
  grid-column: 1;
}

.goalprogressbar {
  grid-row: 2;
  grid-column: 1;
  width: 100%;
  min-width: 200px;
  min-height: 50px;
}

.goalfilled {
  grid-row: 2;
  grid-column: 1;
  min-height: 50px;
}

.goalunfilled {
  grid-row: 2;
  grid-column: 1;
  justify-self: flex-end;
  min-height: 50px;
}

.goalamount{
  grid-row: 2;
  grid-column: 1;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
