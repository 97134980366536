.Payments .buttons {
  margin-bottom: 10px;
  text-align: right;
  padding-right: 30px;
}



@keyframes blink-animation {
  to {
    border: 3px solid black;
  }
}

.payment:hover {
  background-color: black;
}



.payment-info {
  position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 15px;
	margin-right: 15px;
}



.payment-maker-icon {
  vertical-align: middle;
  font-size: 30px;
  padding-bottom: 4px;
}

.payment:hover .replay-button {
	display: initial;
}

.payment:hover .payment-time {
	display: none;
}

.payment.active:hover .replay-button {
	display: none;
}


.payment-menu {
  position: absolute;
  top: 50px;
  right: 35px;
}

.payment-menu button.btn {
  display: block;
  line-height: initial;
  width: 100%;
}

.song-container,
.payment-amount {
  border: 1px solid;
  border-color: rgb(33, 37, 41);
}

.song-container {
  position: absolute;
  right: 0px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 30px;
  background-image: linear-gradient(
    to right,
    rgb(75, 0, 130),
    rgb(138, 43, 226)
  );
}

.payment.active .payment-time {
  display: none;
}

.disabled-alerts-warning {
  display: inline-block;
  position: relative;
  color: white;
  border: 2px solid red;
  padding: 5px 10px 5px 40px;
  border-radius: 5px;
}

.disabled-alerts-warning span {
  position: absolute;
  left: 5px;
}

.payment-date-button {
  background-color: black;
  color: white;
  width: 100%;
  border-left: none;
  border-right: none;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: none;
  background: linear-gradient(to top, rgb(35,40,40),black, rgb(35,40,40));
}

.payment-list {
  padding-top: 10px;
  padding-bottom: 2px;
}

.payment-toggler {
  vertical-align: bottom;
  position: absolute;
  right: 10px;
}

.single-attach-title {
	text-align: right;
	display: inline-block;
	color: #b39ddb;
	text-size: 17px;
}

.single-attach-title .material-symbols-sharp {
	vertical-align: bottom;
}

.payment-time .material-symbols-sharp {
	vertical-align: text-bottom;
	margin-right: 3px;
}

.test-alert-popup {
	position: absolute;
	top: 45%;
}
