.popup {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(30, 30, 30, 0.9);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
