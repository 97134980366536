.preview {
  border: var(--oda-main-border);
  border-radius: 8px;
}

.size {
  min-width: 100px;
}

.deletebutton {
  border: none!important;
  background-color: unset!important;
  padding: 0px!important;
  box-shadow: none!important;
  color: var(--oda-secondary-text-color)!important;
}

.upload{
  color: var(--oda-secondary-text-color);
  width: 200px;
  height: 120px;
  line-height: 106px;
  text-align: center;
  background: var(--oda-btn-background-color);
  border: 2px dashed var(--oda-secondary-text-color);
  & [class*="material-symbols-sharp"] {
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 5px;
  }
  &:hover{
    border: 2px dashed var(--oda-secondary-text-color);
    color: var(--oda-secondary-text-color);
  }
}
