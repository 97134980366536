.paymentpageconfig {
  color: white;
  padding-bottom: 50px;
  flex: 1 0 auto;
  margin-top: 22px;
}

.paymentpage {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widgetsettingsitem {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.widgetsettingsname {
	flex: 1 0 auto;
}

.widgetsettingsvalue {
  display: inline-block;
  width: 120px;
  border-radius: 5px;
  border: 1px solid #684aff;
  background: none;
  color: white;
  text-align: center;
}

.url {
  font-size: 20px;
  color: white;
}

.widgetsettingsvalue:focus-visible,
.widgetsettingsvalue:focus {
	outline:none;
}

.backgroundimage {
	width: 400px;
	height: 300px;
  border: 1px solid #684aff;
}

.logoimage {
	width: 110px;
	height: 110px;
  border: 1px solid #684aff;
}

.backgroundimage:hover {
	cursor:pointer;
}

.buttons {
  width: 100%;
  text-align: right;
}

.cancelButton {
	background: gray;
  color: white;
  border: none;
  border-radius: 5px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
  margin-top: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.saveButton {
	background: green;
  color: white;
  border: none;
  border-radius: 5px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
  margin-top: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}
