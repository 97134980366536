.sliding {
  position: relative;
  animation: linear dotleft 10s infinite;
  white-space: nowrap;
}

.donaters-top {
	text-align: center;
	display: inline;
}

.donaters-title {
	text-align: center;
}

.donaters-list .donater {
  margin-right: 30px;
}

@keyframes dotleft {
  0% {
    left: 50%;
  }
  100% {
    left: 0%;
  }
}
