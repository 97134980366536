.layoutdescription {
  display: inline-block;
  word-wrap: break-word;
  white-space: break-spaces;
  margin-left: 5px;
  padding-top: 5px;
  min-width: 200px;
}

.radiobutton {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.radiobutton:last {
}

.radiobutton img {
  width: 400px;
  height: 120px;
  border: 1px solid #684aff;
  margin-left: 5px;
}

.selected {
  background-color: #684aff;
  color: white !important;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.9);
  border: 1px solid #674afd;
  border-radius: 3px;
}

.radiobutton:hover {
  cursor: pointer;
}

.radiobutton:hover:not(.selected){
  border: 1px solid #684aff;
  background-color: #684aff;
  border-radius: 3px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.9);
}
