.colorpicker {
  max-width: 100px;
}

.label {
  margin-right: 10px;
}

.shadowitem {
  margin-top: 20px;
}
