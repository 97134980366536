.itembackcontainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.itembacklabel{
  flex: 0 0 auto;
  font-family: "Play";
}

.itembackuploadbutton{
  flex:  0 0 auto;
}
