.message {
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

.paymentAlerts {
  height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
  align-items:  center;
  overflow: hidden;
}
