.video-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.player-container {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}

.player-container .rc-slider {
  width: 100px;
}

.player-container .rc-slider-track {
  background-color: #684aff;
}

.playlist {
  flex: 1 1;
}

.playlist-tabs {
  flex: 0 1 auto;
  color: white;
  height: 42px;
  width: 100%;
}

.playlist-tabs .nav-link {
  color: var(--oda-secondary-text-color);
}

.playlist-tabs .nav-link.active {
  color: white;
  border: none;
  background-color: #684aff;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.playlist {
  overflow: hidden;
  width: 100%;
  background-color: var(--oda-panel-backgroun-color);
}

.playlist:hover {
  overflow-y: auto;
}

.playlist::-webkit-scrollbar-button {
  display: none;
}

.playlist::-webkit-scrollbar-thumb {
  background: rgb(33, 37, 41);
}

.playlist .owner {
  color: var(--oda-secondary-text-color);
}

.song-title {
  white-space: nowrap;
  overflow: hidden;
}

.list-group-item.active a {
  color: white;
}

.playlist .list-group-item.active {
  background-color: rgb(13, 110, 253);
}

.link-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  background: rgba(30, 30, 30, 0.9);
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link-popup input {
  top: 50%;
  width: 98%;
}

.item-buttons {
  display: none;
}

.list-group-item:hover {
  background-color: rgb(33, 37, 41);
}

.list-group-item:hover .song-title {
  width: 260px;
  overflow: hidden;
}

.list-group-item:hover .item-buttons {
  display: initial;
}

.item-buttons .btn {
  position: absolute;
  bottom: 13px;
  line-height: 0px;
  background-color: black;
  border: var(--oda-main-border);
}

.video-buttons {
  padding-left: 10px;
  text-align: center;
}

.video-buttons button {
  line-height: 0px;
  margin-left: 0px;
  padding: 0px;
  border: none;
}

.video-buttons button:disabled {
  color: gray;
}

.video-buttons button:hover {
  transform: translateY(-2px);
  background: unset;
  & span{
    color: white;
  }
}

.video-buttons button span {
  font-size: 40px;
  color: var(--oda-secondary-text-color);
}

.item-buttons .btn:hover {
  background-color: white;
}

.item-buttons .btn.play {
  right: 120px;
}

.item-buttons .btn.share {
  right: 65px;
}

.item-buttons .btn.delete {
  right: 10px;
}

.player-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
}

.videoImplToggler {
  flex: 0 0 auto;
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 15px;
}

.videoImpl {
  margin-left: 5px;
  color: white;
  vertical-align: top;
}

.song-title-container {
  flex: 1 1 150px;
  padding-left: 10px;
  padding-top: 10px;
  color: var(--oda-main-text-color);
  min-height: 50px;
  display: inline-block;
  overflow-x: hidden;
  white-space: nowrap;
  width: 150px;
}

.add-button-container .btn.add {
  border: none;
  border-radius: 0px;
}

.progress {
  width: 100%;
  height: 5px;
}

.video-duration {
  color: white;
  padding-top: 15px;
  padding-right: 15px;
  font-size: 14px;
  display: inline-block;
}

.faded {
  color: rgb(170, 170, 170);
}

.nav-item:hover {
  cursor: pointer;
}

.notification-button {
  position: absolute;
  top: 5px;
  line-height: 0px;
  left: 925px;
  border: none;
}

.disabled-media-warning {
  color: white;
  border: 1px solid red;
  background-color: red;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: right;
}

.disabled-media-warning span {
  vertical-align: bottom;
}

.add-button-container {
  /* position: fixed; */
  top: 440px;
  text-align: center;
  min-width: 200px;
  margin-top: 10px;
}

.video-container iframe {
  /* width: 500px !important; */
  height: 300px !important;
  position: initial !important;
  z-index: 0;
}

.video-controls {
  display: flex;
  align-items: baseline;
}

.repeat-button {
  line-height: 0px;
  margin-left: 0px;
  padding: 0px;
  border: none;
  color: white;
  background: none;
  margin-right: 5px;
}

.repeat-button span.material-symbols-sharp{
  vertical-align: middle;
}

.video-counter {
  flex: 0 0;
  color: var(--oda-main-text-color);
  white-space: nowrap;
  padding-left: 20px;
  padding-top: 15px;
  text-align: right;
  padding-right: 10px;
}

.video-owner {
  top: 390px;
  color: white;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  padding-left: 20px;
  padding-bottom: 21px;
}

.add-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  background: rgba(30, 30, 30, 0.9);
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.playlist-controls {
  display: flex;
  border-bottom: 0.5px solid #674afd;
  align-items: baseline;
}

.saved-playlist-item {
  border-radius: 5px;
  margin-left: 3px;
  margin-top: 1px;
  color: rgb(140, 140, 140);
  background-color: rgb(10, 10, 10);
  border: none;
  font-size: 20px;
}

.saved-playlists {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

#new-media-input {
  height: 30px;
  background-color: rgb(50, 50, 60);
  border: 1px solid rgb(110, 110, 110);
  border-radius: 3px;
  margin-bottom: 10px;
  display: block;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
}

#new-media-input:focus {
  outline: none;
}

#close-add-media-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 0;
  color: white;
  background-color: rgba(30, 30, 30, 0.9);
  border: none;
}

.add-media-popup-description {
  color: white;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.videoImplToggler {
  height: 100%;
}

.videoImplToggler button {
  border-radius: 5px;
  background: none;
  color: white;
  height: 30px;
  border: 1x solid rgb(110, 110, 110);
}
