.ant-btn-default {
  color: var(--oda-secondary-text-color);
}

.ant-btn-primary {
  color:  var(--oda-main-text-color);
}

.ant-input {
  border: var(--oda-main-border);
  font-family: 'Play';
  font-size: 18px;
  background-color: var(--oda-control-background-color);
  padding-top: 3px;
  padding-bottom: 3px;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.9);
  border-radius: 3px;
}

.ant-input-number {
  background-color: unset;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--oda-secondary-text-color);
  border-radius: 0px;

  &:focus,
  &:focus-within,
  &:hover,
  & input:focus,
  & input:hover,
  & input:focus-within {
    background-color: unset!important;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    outline: none!important;
  }

  & .ant-input-number-input {
    padding-top: 0px;
    padding-bottom: 1px;
  }

  & .ant-input-number-handler {
    display: none;
  }

  & .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-input-number-group .ant-input-number-group-addon {
  font-family: 'Play'!important;
  font-weight: 900!important;
  font-size: 20px!important;
  padding-left: 5px;
  padding-right: 5px;
  /* color: var(--oda-secondary-text-color); */
  color: rgba(180, 197, 228, 0.4);
}

.ant-input-number-group-wrapper-outlined
  .ant-input-number-group-addon:last-child {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid var(--oda-secondary-text-color);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: none;
}

.ant-color-picker-trigger {
  width: 100%;
  min-width: 170px;
  justify-content: flex-start;
  flex-flow: row-reverse;
  padding-right: 10px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  height: unset;
  min-height: 28px;
  & .ant-color-picker-trigger-text{
    color: var(--oda-secondary-text-color);
    font-size: 20px;
    font-family: "Play";
  }
  & .ant-color-picker-color-block {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }
}


.ant-tabs.ant-tabs-card {
  flex: 1 1 auto;
  font-family: "Play";

  & .ant-tabs.ant-tabs-card {
    &.ant-tabs-top {
        & .ant-tabs-nav {
          background: none;
          height: 37px;
        }
    }
  }


  &.ant-tabs-top {
    & .ant-tabs-nav {
      padding-left: 5px;
      margin-bottom: 0px;
      background-color: var(--oda-main-background-color);

      &::before{
        border-bottom: 1px solid #674afd;
        z-index: 0;
      }

      & .ant-tabs-tab {
        border: none;
        color: #7885bd;
        font-family: "Play";
        font-weight: 900;
        height: 44px;

        &.ant-tabs-tab-active {
          background-color: var(--oda-panel-backgroun-color);
          border-bottom: none;
          border-left: 1px solid #684aff;
          border-right: 1px solid #684aff;
          border-top: 1px solid #684aff;
          & .ant-tabs-tab-btn {
            color:  var(--oda-main-text-color);
          }
        }

      }

    }
  }

  & .ant-tabs-content-holder {
    background: var(--oda-panel-backgroun-color);
    border-radius: 3px;
    border: none;
  }

  & .ant-tabs-tab-btn {
    color: var(--oda-secondary-text-color);
  }
}

.ant-modal {
  font-family: 'Play';
  & .ant-modal-header {
    margin-left: 10px;
    background-color: var(--oda-panel-backgroun-color);
    margin-bottom: 0px;
    padding-left: 10px;
    font-weight: 900;
  }


  & .ant-modal-content {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: var(--oda-panel-backgroun-color);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.5);
    border: 1px solid rgb(43, 53, 102);
    margin-bottom: 0px;
    min-width: 800px;


    & .ant-modal-title {
      color: var(--oda-main-text-color);
      text-align: left;
      background-color: var(--oda-panel-backgroun-color);
      font-weight: 900;
      font-size: 20px;
    }

    & .ant-modal-body {
      background-color: var(--oda-panel-backgroun-color);
      border-radius: 10px;
      font-size: 18px;
    }
  }

  & .ant-tabs {
    padding-top: 10px;
    background-color: var(--oda-main-background-color);
  }

  & .ant-modal-footer {
    background-color: var(--oda-panel-backgroun-color);
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-top: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.ant-collapse {
  background-color: #151a33;
  font-family: Play;
  border: 1px solid #674afd;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;

  & .ant-collapse-content {
    background-color: #2b3566;

    & > .ant-collapse-content-box {
      padding: 0px;
    }
  }

  & > .ant-collapse-item > .ant-collapse-header {
    font-family: Play;
    font-weight: 900;
    color: white;
  }

  & > .ant-collapse-item > .ant-collapse-header:first {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ant-layout {
  background: none !important;
  font-family: "Play";

  & .ant-layout-sider {
    background: none !important;
  }

  & .ant-layout-header {
    background: none !important;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.8);
  }
}

.ant-dropdown-trigger {
  color: var(--oda-secondary-text-color);
  font-weight: 900;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    transform: translateY(-2px);
    color: white;
  }

  & span {
    vertical-align: middle;
  }
}

aside.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: unset !important;
}

main.ant-layout-content {
  flex: 1 0 600px !important;
  padding-left: 10px;
}

div.ant-layout-has-sider.ant-layout {
  flex: 1 0 auto;
  align-self: center;
  width: 100%;
  max-width: 1200px !important;
}

.ant-dropdown {
  & .ant-dropdown-menu {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.8);
    background-color: var(--oda-btn-background-color);
    border-radius: 6px;
    border: 1px solid rgb(43, 53, 102);
    & .ant-dropdown-menu-item:hover{
      background-color: var(--oda-panel-backgroun-color);
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
      & .ant-dropdown-menu-title-content {
        color: var(--oda-main-text-color);
      }
    }
  }
}

.ant-dropdown-menu-title-content {
  color: var(--oda-secondary-text-color);
}

.ant-checkbox {
  & .ant-checkbox-inner {
    height: 25px;
    width: 25px;
  }
}

.ant-select {
  &.ant-select-outlined {
    border: 1px solid var(--oda-secondary-text-color);
    border-radius: 5px;
    /* background: var(--oda-secondary-text-color); */
    & .ant-select-selection-item {
      text-align: right;
      font-family: 'Play';
      font-size: 20px;
      font-weight: 600;
      color: var(--oda-secondary-text-color) !important;
    }
    & .ant-select-selector {
      background-color: transparent;
      border: none;
    }
  }
  & .ant-select-arrow {
    color: var(--oda-secondary-text-color);
  }
}

.ant-carousel {
  flex: 1 0 auto;
  display: flex;
}

.ant-segmented {
  background: var(--oda-btn-background-color);
  color: white;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  font-family: 'Play';

  & .ant-segmented-item {
    border-radius: 20px;
  }

  & .ant-segmented-item-selected {
    background: var(--oda-secondary-text-color);
    color: var(--oda-label-background-color);
  }
}

.ant-switch {
  background: var(--oda-btn-background-color);
  height: 24px;

  &.ant-switch-checked {
    background-color: var(--oda-secondary-text-color);
  }
  & .ant-switch-handle {
    top: 3px;
  }

  &:hover:not(.ant-switch-disabled) {
    box-shadow: 0 0 5px 1px black;
    background: var(--oda-btn-background-color);
  }
}

.ant-col {
  font-family: "Play";
}

.ant-btn-sm {
  height: 32px;
}

.ant-picker-now-btn {
  color: var(--oda-secondary-text-color);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background: var(--oda-btn-background-color);
  color: var(--oda-main-text-color);
}
.ant-picker-outlined {
  border: 1px solid var(--oda-secondary-text-color);
  background: unset;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-width: 2px;

  & .ant-picker-input > input {
    color: var(--oda-secondary-text-color);
    font-family: "Play";
    font-weight: 900;
    font-size: 20px;
  }

  & .ant-picker-clear {
    color: var(--oda-secondary-text-color);
  }

  & .ant-picker-suffix {
    color: var(--oda-secondary-text-color);
  }
}

.ant-spin .ant-spin-dot-item {
  background-color: #674afd;
}

.ant-qrcode {
  border: none;
}
