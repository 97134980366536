.button {
  position: absolute;
  right: 0;
  width: 25%;
}

.addanimationbuttoncontainer{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 10px;
}

.demo{
  text-align: center;
  padding: 2px;
  border-radius: 4px;
  min-height: 40px;
  width: 100%;
  z-index: 2;
  position: relative;
}

.background {
  width: calc(100% - 40px);
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 1;
  filter:blur(1px);
}

.democontainer {
  position: relative;
  background-color: var(--oda-main-background-color);
  padding-left: 20px;
  padding-right: 20px;
}

.modal {
  &[class*="ant-modal"] {
    & [class*="ant-modal-content"] {
      background-color: var(--oda-main-background-color);
      & [class*="ant-modal-header"] {
        background-color: var(--oda-main-background-color);
        & [class*="ant-modal-title"] {
          background-color: var(--oda-main-background-color);
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }
  }
}
