.window{
  width: min-content;
  & [class*="ant-modal"] {
    & [class*="ant-modal-content"] {
      width: min-content;
      min-width: 600px;
      min-height: 600px;
    }
    & [class*="ant-modal-footer"] {
      display: none;
    }
  }
}

.window .url {
  &[class*="ant-input"] {
    border: none;
    box-shadow: none;
    background: unset;
    border-radius: 0px;
    font-weight: 900;
    color: white;
    border-bottom: 1px solid #674afd;
    &:focus {
      outline: none;
      border-bottom: 2px solid #674afd;
    }
  }
}

.spin{
  min-width: 600px;
  min-height: 600px;
}

.modal {
  padding: 20px;
}

.qrcodedescription {
  margin-top: 30px;
}
