.newscontainer {
  position: relative;
  background-color: rgb(251, 255, 241);
  margin-top: 20px;
  padding-top: 8px;
  border-radius: 8px;
  animation: shadow 2s infinite;
  text-align: center;
  margin-bottom: 20px;
}

.closebutton {
  position: absolute;
  right: 0;
  top: 20px;
}

.closebutton button {
  border: none;
  background-color: transparent;
}

.progressbar {
  width: 100%;
  background-color: var(--oda-control-background-color);
  height: 10px;
}

.filledprogress {
  background-color: rgb(252, 255, 241);
  height: 10px;
}

@keyframes shadow {
  0% {box-shadow: none;}
  50% {box-shadow: 0 0px 10px 5px rgb(122,122,122, 0.5);}
  100% {box-shadow: 0 0px 10px 5px rgb(122,122,122, 1);}
}

.newstitle {
  font-size: 20px;
  text-decoration: underline;
  margin-left: 40px;
  margin-right: 40px;
  font-weight: bold;
}

.newsbody {
  margin-left: 10px;
  margin-right: 10px;
  text-wrap: balance;
  min-width: 200px;
  text-align: justify;
  flex: 2 1 auto;
}

.newsdemo{
  border-radius: 8px;
  border: 2px solid var(--oda-control-background-color);
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 200px;
  min-height: 200px;
}

.buttons{
  margin-bottom: 20px;
  margin-top: 20px;
  & img {
    width: 90px;
  }
  & img:hover {
    transform: translateY(-10px);
  }
}

@media(max-width: 800px){
  .newscontainer {
    margin-left: 20px;
    margin-right: 20px;
  }
  .bodycontainer {
    flex-direction: column-reverse;
  }
}

@media(min-width: 800px){
  .newscontainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }
  .newsdemo {
    min-width: 400px;
  }
  .bodycontainer {
    flex-direction: row;
  }
}
