.widget-button-url .material-symbols-sharp {
  margin-left: -2px;
  vertical-align: bottom;
}

.widget-button-list {
  min-width: 50px;
  margin-top: 20px;
  position: relative;
  text-align: right;
  right: 0;
  display: inline-block;
}

.widget-button-list .widget-button {
  border: none;
  background: none;
  color: rgba(255,255,255,0.7);
}

/* CSS */
.widget-button {
  border: 1px solid #151a33;
  background-color: #151a33;
  color: #7885bd;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Play;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  outline: none;
  outline: 0;
  text-align: center;
  transform: translateY(0);
  transition:
    transform 150ms,
    box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  padding: 5px 5px;
}

.widget-button span {
  line-height: 0;
  vertical-align: middle;
}

.widget-button:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
  color: #674afd;
}

.widget-button.widget-button-more:hover {
  color:white;
  box-shadow: none;
}

.widget-button.widget-button-more {
  box-shadow: none;
}

button.ant-btn {
  border-radius: 5px;
  font-weight: 900;
  border: 1px solid #684aff;
  background-color: #151a33;
  padding: 4px 15px;
  font-size: 14px;
  font-family: Play;
}

.widget-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  /* todo make all buttons jump on hover */
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  color: #674afd;
  border: 1px solid #674afd;
}

.widget-button.widget-button-accept {
  color: var(--oda-label-background-color);
  height: 36px;
  font-weight: 900;
  font-size: 14px;
  margin-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  background: var(--oda-secondary-text-color);
  border: none;
  border-radius: 5px;
}

.blinker {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.widget-button.widget-button-accept:hover .blinker {
  animation: none;
}

.widget-button.widget-button-decline .blinker {
  animation: none;
}

.widget-button.widget-button-decline {
  height: 38px;
  border: 1px solid var(--oda-panel-backgroun-color);
  border-radius: 5px;
  color: var(--oda-secondary-text-color);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 10px;
  font-weight: 900;
  font-size: 14px;
}
