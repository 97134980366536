.headercontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-shadow: 0 0 1px #674afd;
}

@media (max-width: 1500px) {
  .headercontainer {
    width: 100%;
  }
}

@media (min-width: 1501px) {
  .headercontainer {
    min-width: 1200px;
    max-width: 1250px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.account {
  justify-self: flex-end;
}

.buttons {
  display: flex;
  align-items: center;

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: var(--oda-secondary-text-color);
    margin-right: 5px;
  }
  & a:hover {
    transform: translateY(-2px);
    filter: brightness(100);
  }
}
