.preview {
  background: var(--oda-main-background-color);
  padding-bottom:10px;
}

.resizable {
  box-shadow: inset 0 0 12px 6px rgba(0, 0, 0, 0.8), 0 0 12px 6px rgba(0, 0, 0, 0.8);
  border: 1px solid rgb(43, 53, 102);
  border-radius: 8px;
  margin-bottom: 10px;
  background: url(https://api.oda.digital/public/commonback.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacearound {
  padding: 80px;
}

.helpbutton {
  background: none;
  border: none;
  color: var(--oda-secondary-text-color);
  height: 100%;
  line-height: 28px;
  padding-right: 0px;
  margin-left: 10px;

  & span {
    vertical-align: bottom;
  }
  &:hover{
    transform: translateY(-2px);
    color: white;
  }
}

/* TODO: cleanup */
.helpmodal {
  & [class*="ant-modal-header"] {
    display: none;
  }
  & [class*="ant-modal-content"] {
    padding-top: 0px;
    background-color: var(--oda-panel-backgroun-color)!important;
  }
  & [class*="ant-modal-body"] {
    background-color: var(--oda-panel-backgroun-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
  }
  & [class*="ant-modal-footer"] {
    display: none;
  }
}

.helptitle {
  font-family: "Play";
  padding: 10px 0 0px 10px;
  margin-bottom: 10px;
  font-weight: 900;
}

.helpdescription {
  margin-left: 25px;
  margin-bottom: 10px;
}

.helplist {
  margin: 0;
  padding: 10px 0 0px 10px;
  & li{
    margin-left: 0.5rem;
    list-style: none;
    & a {
      color: var(--oda-secondary-text-color);
      font-size: 20px;
    }
  }
}

.helplistitem {
  display: flex;
  align-items: center;
  gap: 5px;
}
