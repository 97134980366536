.checkboxwrapper {
  text-align: right;
  margin-top: 10px;
  height: 36px;
}
.checkboxwrapper input[type="checkbox"] {
  background-image:
    -webkit-linear-gradient(hsla(0, 0%, 0%, 0.1), hsla(0, 0%, 100%, 0.1)),
    -webkit-linear-gradient(left, #f66 50%, #6cf 50%);
  background-size:
    100% 100%,
    200% 100%;
  background-position:
    0 0,
    15px 0;
  border-radius: 25px;
  box-shadow:
    inset 0 1px 4px hsla(0, 0%, 0%, 0.5),
    inset 0 0 10px hsla(0, 0%, 0%, 0.5),
    0 0 0 1px hsla(0, 0%, 0%, 0.1),
    0 -1px 2px 2px hsla(0, 0%, 0%, 0.25),
    0 2px 2px 2px hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  height: 23px;
  padding-right: 25px;
  width: 70px;
  -webkit-appearance: none;
  -webkit-transition: 0.25s;
}

.checkboxwrapper input[type="checkbox"]:after {
  background-color: #eee;
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 0%, 0.1)
  );
  border-radius: 25px;
  box-shadow:
    inset 0 1px 1px 1px hsla(0, 0%, 100%, 1),
    inset 0 -1px 1px 1px hsla(0, 0%, 0%, 0.25),
    0 1px 3px 1px hsla(0, 0%, 0%, 0.5),
    0 0 2px hsla(0, 0%, 0%, 0.25);
  content: "";
  display: block;
  height: 25px;
  width: 50px;
}

.checkboxwrapper input[type="checkbox"]:checked {
  background-position:
    0 0,
    35px 0;
  padding-left: 25px;
  padding-right: 0;
}
