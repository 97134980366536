.unitselector {
  min-width: 100px;
}

.equalsign {
  margin-left: 5px;
  margin-right: 25px;
  color: var(--oda-secondary-text-color);
  font-size: 30px;
  line-height: 0px;
  font-weight: 900;
}
