.payment {
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: var(--oda-panel-background-color);
  color: var(--oda-main-text-color);
  border-radius: 5px;
  border: 1px solid rgb(43, 53, 102);
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.5);
  &[class*="active"] {
    border-color: var(--oda-secondary-text-color);
    box-shadow: 0px 0px 2px 2px var(--oda-main-text-color);
  }
  &:hover .replaybutton {
    display: initial;
  }
  &[class*="active"]:hover .replaybutton {
    display: none;
  }
}

.paymentheader {
  position: relative;
  display: flex;
  justify-content: space-between;
	margin-left: 10px;
	margin-right: 15px;
	margin-top: 5px;
}

.paymentamount {
  text-align: left;
  background-image: linear-gradient(to right, rgb(255, 191, 52), #ec5800);
  display: inline;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 28px;
  font-weight: bold;
}

.paymentbody {
  color: rgb(180, 197, 228);
  font-size: 19px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	padding-left: 10px;
	padding-right: 15px;
	padding-top: 3px;
  border-top: var(--oda-main-border);
  background-color: rgb(43, 53, 102);
  box-shadow: 0 -10px 10px 1px rgba(0, 0, 0, 0.5);
  user-select: text;
}

.paymentinfo {
  position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
  align-items: flex-start;
	margin-left: 5px;
  margin-bottom: 5px;
}

.paymenttime {
  font-size: 20px;
  text-wrap: nowrap;
  color: var(--oda-secondary-text-color);

  [class*="active"] & {
    display: none;
  }
}

button.replaybutton {
  line-height: 0px;
  height: min-content;
  color: var(--oda-secondary-text-color);
  background: transparent!important;
  padding-top: 7px;
  border: none;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
  &:hover{
    background: transparent!important;
  }

  [class*="active"] & {
    display: none;
  }

  & span {
    font-weight: bolder;
  }
}

button.stopbutton {
  color: red!important;
  display: none;
  line-height: 0px;
  height: min-content;
  background: transparent!important;
  border: none;
  box-shadow: none;
  padding-top: 7px;
  padding-left: 0px;
  padding-right: 0px;

  [class*="active"] & {
    display: initial;
  }

  & span {
    font-weight: bolder;
  }
}

.nickname {
  display: inline-block;
  font-size: 28px;
  font-weight: bold;
}

.singleattachtitle {
	color: #b39ddb;

  & span[class*="material-symbols-sharp"] {
    vertical-align: middle;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

}
