.panelcontainer {
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--oda-main-text-color);
}

.savedPlaylists {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.editPlaylistNameInput {
  padding-top: 15px;
  padding-bottom: 15px;
}

.modal {
  height: 1000px;

  & div[class*="ant-modal-body"] {
    min-height: 50px;
  }
  & div[class*="ant-modal-content"] {
    min-width: unset;
  }
}

.panel {
  border-radius: 8px;
  display: flex;
  flex-direction: row;

  & button {
    background: none;
    color: var(--oda-main-text-color);
    border: none;
    & span {
      vertical-align: middle;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}

.deletebutton {
  background: none;
  border: none;
  color: var(--oda-secondary-text-color);
  height: 100%;
  line-height: 28px;
  padding-right: 0px;
  margin-left: 10px;

  & span {
    vertical-align: bottom;
  }
  &:hover {
    transform: translateY(-2px);
    color: white;
  }
}
