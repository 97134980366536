.widget-list {
  min-width: 900px;
  padding-bottom: 50px;
  flex: 1 1 auto;
}

.widget-list-title {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 200px;
  color: #7987bf;
}
