.inputcontainer {
  width: 100%;
  justify-content: flex-end;
}

.button {
  background: unset;
  border: none;
  vertical-align: middle;
  line-height: 0;
  color: var(--oda-secondary-text-color);
}

.valuewithaddon input {
  text-align: right!important;
}

.valuewithoutaddon input {
  text-align: center!important;
}

.value input {
  font-family: 'Play'!important;
  font-weight: 900;
  width: 90px!important;
  font-size: 20px!important;
  color: var(--oda-secondary-text-color)!important;
}


.value:focus,
.value:focus-within,
.value:hover,
.value input:focus,
.value input:hover,
.value input:focus-within {
  background-color: unset!important;
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;
  outline: none!important;
}
