:root {
  --oda-control-background-color: #684aff;
  --oda-main-background-color: #0c122e;
  --oda-label-background-color: #0c122e;
  --oda-btn-background-color: #0c122e;
  --oda-main-border: 1px solid var(--oda-control-background-color);
  --oda-main-font-family: Play;
  --oda-main-text-color: #FBFFF1;
  --oda-secondary-text-color: #B4C5E4;
  --oda-label-font: 1rem var(--oda-main-font-family);
  --oda-label-color: #ffffffda;
  --oda-panel-backgroun-color: #2b3566;
}

.widget-settings button.oda-btn-default,
.oda-btn-default,
button.ant-btn.ant-btn-default,
button.ant-btn.ant-btn-primary {
  min-height: 36px;
  color: var(--oda-main-text-color);
  background-color: var(--oda-btn-background-color);
  border: none;
  background: var(--oda-secondary-text-color);
  color: var(--oda-label-background-color);

  font-family: var(--oda-main-font-family);
  font-size: 14px;
  border-radius: 5px;
  font-weight: 900;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
  padding: 6px 15px;
}

.ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default{
  display: none;
}

#root button.oda-btn-default:hover,
.oda-btn-default:hover,
.ant-btn-default:hover,
#root button.ant-btn.ant-btn-default:hover,
button.ant-btn.ant-btn-primary:hover {
  cursor: pointer!important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0!important;
  border-color: #3c89e8!important;
  color: var(--oda-label-background-color)!important;
  background-color: var(--oda-secondary-text-color)!important;
  transform: translateY(-2px)!important;
}

.configuration-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widget-settings {
  color: white;
}

.widget-settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 10px;
  min-height: 45px;
  flex-wrap: wrap;
  border-bottom: 1px dashed #0c122e;
}

.widget-settings .widget-settings-item:last-child {
  border: none;
}

.widget-settings-name {
  color: white;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  cursor: text;
  top: 10px;
  font-family: "Play";
}
.payment-alert-settings .widget-settings-name {
  width: 50%;
}

.widget-settings-value:focus-visible,
.widget-settings-value:focus {
  outline: none;
}

.widget-settings-value:hover {
  cursor: pointer;
}

.widget-settings-item,
.widget-settings-item label:hover {
  cursor: default;
}

.widget-settings .ant-divider-horizontal.ant-divider-with-text-left::before {
  border-color: #674afd;
}

.widget-settings .ant-divider-horizontal.ant-divider-with-text-left::after {
  border-color: #674afd;
}

.widget-settings
  .ant-color-picker-trigger
  .ant-color-picker-color-block
  .ant-color-picker-color-block-inner {
  border-radius: 2px;
}

.widget-settings-item .ant-select {
  position: absolute;
  right: 0;
  width: 25%;
  color: white;
}

.widget-settings-item .ant-select .ant-select-arrow {
  color: white;
}

.widget-settings .ant-btn-default {
  border: 1px solid #151a33;
  background-color: #151a33;
  box-shadow: none;
}

.configuration-container .ant-btn-default {
  border: 1px solid #151a33;
  background-color: #151a33;
  box-shadow: none;
}

.configuration-container .widget-header .ant-btn-default {
  border-radius: 20px;
  font-weight: 900;
}

.configuration-container .widget-header .ant-btn-default:not(:hover) {
  color: white;
  border: 1px solid #674afd;
  font-family: Play;
}

.widget-settings-value {
  font-family: inherit;
  width: 25%;
  outline: 0;
  font-size: 1rem;
  font-family: "Play";
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  text-align: right;
  border: 1px solid #674afd;
  border-radius: 3px;
}

.textarea-container {
  width: 100%;
  text-align: right;
  padding-top: 5px;
  border-radius: 0px;
  padding: 10px;
}

.widget-settings-value {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

textarea.widget-settings-value {
  text-align: left;
  width: 100%;
  padding: 10px;
  resize: none;
  font-size: 18px;
  background-color: var(--oda-main-background-color);
  box-shadow: inset 0 0 10px 0px rgba(0, 0, 0, 0.9);
  border-radius: 5px;
}

.widget-settings-value.select {
  width: 100%;
  border: none;
  background-color: rgb(43, 53, 102);
}

.widget-settings-value.select:focus,
.widget-settings-value.select:focus-visible {
  outline: none;
}

.widget-settings-value.select option {
  color: white;
}

.widget-settings-value.select option {
  background-color: none;
}

.payment-alerts-previews-item {
  min-height: 350px;
  display: flex;
  padding: 0px;
  position: relative;
  border: var(--oda-main-border);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  & .ant-tabs {
    &.ant-tabs-top {
      padding-top: 10px;
      & .ant-tabs-nav {
        background: unset;
      }
    }
  }
}

.image-preview-container {
  background-color: black;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #674afd;
}

.payment-alerts-previews-item {
  margin-bottom: 10px;
}

.payment-alert-image-preview {
  width: 200px;
  display: flex;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  justify-content: center;
  align-items: center;
}

div.payment-alert-image-preview {
  border-right: 1px solid #674afd;
}

.alert-no-image {
  filter: invert(1);
}

.widget-settings-value.ant-input {
  background-color: #684aff;
  width: 25%;
  font-size: 14px;
  position: absolute;
  right: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  text-align: left;
  padding-left: 8px;
}

.payment-alert-image-preview:hover {
  cursor: pointer;
}

.payment-alert-settings {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #684aff;
}

.payment-alert-settings-tabs {
  padding-top: 5px;
  margin-bottom: 20px;
}

.payment-alert-settings-tab-image {
  display: inline-block;
}

.payment-alert-settings-tab-voice {
  display: inline-block;
}

.settings-tab-item {
  margin-right: 10px;
  background-color: #111529;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #7885bd;
  user-select: none;
}

.settings-tab-item:hover {
  cursor: pointer;
}

.settings-tab-item.active {
  background-color: #684aff;
  color: white;
}

.color-value {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 3px solid white;
  display: inline-block;
}

.color-value:hover {
  cursor: pointer;
}

.color-picker-popup {
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 1);
  padding: 2px;
  border: 1px solid #684aff;
}

.color-picker-popup .react-colorful {
  width: initial;
}

.color-picker-value {
  border-radius: 5px;
}

.widget-settings-radiobutton img {
  width: 400px;
  height: 120px;
  margin-bottom: 20px;
  border: 1px solid #684aff;
  margin-left: 5px;
}

.widget-settings-radiobutton img:hover {
  cursor: pointer;
}

.widget-settings-radiocontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.configuration-container .ant-table {
  border: var(--oda-main-border);
}

.configuration-container .ant-pagination .ant-pagination-item-active {
  border: var(--oda-main-border);
  background-color: #684aff;
  color: white !important;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.9);
  border-radius: 3px;
}

.configuration-container .ant-pagination .ant-pagination-item {
  color: white;
}

.configuration-container .ant-pagination .ant-pagination-item-link {
  color: white;
}

.configuration-container .ant-pagination .ant-pagination-item-link:hover {
  color: white;
}

.configuration-container
  .ant-descriptions
  .ant-descriptions-item-container
  .ant-descriptions-item-label {
  margin-right: 10px;
  color: black;
  font-weight: 900;
}

.configuration-container
  .ant-descriptions
  .ant-descriptions-item-container
  .ant-descriptions-item-label::after {
  display: none;
}

.configuration-container .ant-list-split .ant-list-item {
  background-color: #151a33;
  padding: 4px 15px;
  border-radius: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.9);
}

.configuration-container .ant-list .ant-list-item .ant-list-item-meta-title {
  color: white;
  font-size: 24px;
}

.configuration-container .ant-list .ant-list-item .material-symbols-sharp {
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.9);
  color: #684aff;
}

.configuration-container
  .ant-list
  .ant-list-item
  .ant-list-item-meta-title
  span.material-symbols-sharp {
  color: var(--oda-secondary-text-color);
}

.configuration-container
  .ant-list
  .ant-list-item
  .ant-list-item-meta-title
  .material-symbols-sharp {
  vertical-align: middle;
  margin-right: 10px;
  color: black;
}

.configuration-container .ant-descriptions-item-content {
  color: white;
}

.configuration-container
  .ant-pagination-options
  .ant-select
  .ant-select-selector {
  border: var(--oda-main-border);
  background-color: var(--oda-btn-background-color);
}

.menu-button {
  border: none;
  background: none;
  color: var(--oda-secondary-text-color);
}

.menu-button:hover {
  transform: translateY(-2px);
  color: white;
}

.settings-item {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  &:nth-child(even) {
    background-color: rgb(12, 18, 46, 0.12);
  }
}

.widget-settings .payment-alerts-previews-item button.oda-btn-default {
  width: 100%;
}
