.pagecontainer {
  margin-left: 20px;
  margin-top: 20px;
}

.little {
  font-size: 16px;
  color: rgb(120, 133, 189);
  margin-left: 3px;
  margin-right: 3px;
}

.timestamp {
  justify-self: flex-end;
  text-align: right;
  flex: 1 0 auto;
  color: rgb(120, 133, 189);
  padding-left: 20px;
}

.title {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.newdonationnickname {
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.newdonationamount {
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.optionscontainer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.optionname{
  margin-right: 10px;
  margin-left: 10px;
}

.option{
  display: flex;
}
