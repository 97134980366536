.helpbutton {
  background: none;
  border: none;
  color: var(--oda-secondary-text-color);
  height: 100%;

  & span {
    vertical-align: bottom;
  }
}

.helpmodal {
  & [class*="ant-modal-header"] {
    display: none;
  }
  & [class*="ant-modal-content"] {
    padding-top: 0px;
    background-color: var(--oda-panel-backgroun-color)!important;
  }
  & [class*="ant-modal-body"] {
    background-color: var(--oda-panel-backgroun-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
  }
  & [class*="ant-modal-footer"] {
    display: none;
  }
}

.helptitle {
  font-family: "Play";
  padding: 10px 0 0px 10px;
  margin-bottom: 10px;
  font-weight: 900;
}

.helpdescription {
  margin-left: 25px;
  margin-bottom: 10px;
  & a {
    color: white;
    font-weight: 900;
    text-decoration-color: var(--oda-control-background-color);
    text-decoration-thickness: 4px;
    text-underline-offset: 5px;
  }
}

.helplist {
  margin: 0;
  padding: 10px 0 0px 10px;
  & li{
    margin-left: 0.5rem;
    list-style: none;
    & a {
      color: var(--oda-secondary-text-color);
      font-size: 20px;
    }
  }
}

.helplistitem {
  display: flex;
  align-items: center;
  gap: 5px;
}
