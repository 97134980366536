.reelitem {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;

  font-size: 30px;
  height: min-content;
}

.reelitemcontainer {
  border: 1px solid;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.active {
  background-color: green;
  color: white;
  animation: blinker 1s linear infinite;
}

.notactive {
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
