.timer{
  width: 100%;
  height: 100%;
  position: relative;
  & div {
    z-index: 2;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.text {
  width: 100%;
}
