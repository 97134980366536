.goalcontainer {
  position: relative;
}

.button {
  margin-bottom: 10px;
}

.deletebutton {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: 900;
  border: 1px solid #684aff;
  color: white;
  background-color: #151a33;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  padding: 4px 25px;
}
