.player-info-container {
  text-align: right;
  /* color: white; */
  /* font-size: 1.5em; */
  padding-right: 10px;
}

.player-info-text {
  /* text-shadow: 2px 2px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, */
    /* -1px 1px 0 #000, 1px 1px 0 #000; */
	/* background-color: rgba(0,0,0,.7); */
}
