.preview {
  border-radius: 8px;
  border: 1px solid var(--oda-panel-backgroun-color);
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.3);
}

.grid {
  padding-left: 25px;
  padding-right: 25px;
}

.guide {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 18px;
}
