body {
  margin: 0;
  font-family: Play;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.material-symbols-sharp {
  font-family: "Material Symbols Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}

button, div, span {
  outline: none !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.vjs-control-bar,
.vjs-poster,
.vjs-modal-dialog,
.vjs-big-play-button,
.vjs-loading-spinner {
  display: none !important;
}

.playlist .list-group {
  border-radius: 0px;
  background-color: rgb(43, 53, 102);
}

.playlist .list-group-item {
  border-radius: 0px;
  color: var(--oda-main-text-color);
  border: none;
  position: relative;
}

.playlist .list-group-item {
  background-color: rgb(43, 53, 102);
}

.add-button-container .add {
  width: 100%;
  text-align: center;
  line-height: 0;
}

.add-button-container .add span {
  width: 100%;
  font-size: 30px;
}

.add-popup {
  width: 100%;
}

.widget-menu {
  z-index: 5000;
  text-align: right;
  border: 1px solid gray;
  min-width: 250px;
}

.widget-menu button {
  width: 100%;
  border-radius: 0px;
  border-bottom: 1px solid gray;
}

.upload-button-container {
  width: 100%;
  text-align: right;
}

.oda-button {
  border: none;
  background-color: #151a33;
  color: #7885bd;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  border-radius: 4px;
}
.upload-button {
  background-color: #151a33;
  color: #7885bd;
  padding-top: 7px;
  padding-bottom: 9px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  border-radius: 4px;
}

.upload-button:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
  color: #674afd;
}

input[type="file"] {
  display: none;
}

.hidden {
  visibility: hidden;
}

.glide__slides {
  align-items: center;
}

.glide {
  overflow-wrap: break-word;
}

.blured {
  filter: blur(5px);
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}


/* resize styles */
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.grow {
  flex-grow: 1;
}

.shrink {
  flex-shrink: 1;
}

.backgroundClipText{
  background-clip: text;
  -webkit-background-clip: text;
}

::-webkit-scrollbar {
    background-color: black;
    display: none;
}

::-webkit-scrollbar-thumb {
    background: rgb(33, 37, 41);
}
