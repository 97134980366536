.widget {
  background-color: #2b3566;
  border-radius: 4px;
  /* border-top: 1px solid rgb(43, 53, 102); */
  /* border-radius: 10px; */
  margin-top: 10px;
  position: relative;
  box-shadow: 0 0 3px 7px rgba(0, 0, 0, 0.2);
}

.widget-header {
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.widget-header-toogler {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.widget.collapsed .widget-header {
  background: none;
  border-bottom: none;
}

.widget.collapsed .widget-header:hover {
  background-image: linear-gradient(180deg, #2b3566, #5d4cb4, #2b3566);
}

.widget.extended {
    /* border: 1px solid rgb(43, 53, 102); */
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.8);
    & .ant-tabs.ant-tabs-card {
      & .ant-tabs-content-holder {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
}

.widget.extended .widget-header{
  background-color: #0c122e;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

}

.widget.extended .widget-header:hover {
  background-image: linear-gradient(180deg, #0c122e 0%, #181556, #0c122e);
}

.widget-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.widget-title {
  color: var(--oda-main-text-color);
  display: inline-block;
  line-height: 40px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 24px;
  font-family: Play;
  margin-left: 10px;
}

.widget-header-toogler:hover {
  cursor: pointer;
}

.widget-title .material-symbols-sharp {
  vertical-align: middle;
}

.new-widget-popup {
  margin-top: 20px;
  color: white;
  display: flex;
  background-color: #2b3566;
  border-radius: 5px;
  border: 1px solid #674afd;
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 13px;
  padding-bottom: 8px;
}

.new-widget-type-button {
  border-radius: 8px;
  border: 2px solid #684aff;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin-top: 10px;
  width: 120px;
  text-align: center;
  padding-top: 10px;
  height: 106px;
}

.new-widget-type-button:hover {
  background-image: linear-gradient(180deg, #2b3566, #5d4cb4, #2b3566);
  cursor: pointer;
}

.new-widget-mock:hover {
  cursor: pointer;
}

.widget-button-menu {
  color: #6b7bbc;
  background-color: #151a33;
  position: absolute;
  width: 150px;
  right: 15px;
  top: 65px;
  z-index: 2;
}

.widget-menu-item {
  padding-left: 10px;
  padding-top: 4px;
  border: 1px solid #674afd;
}

.widget-menu-item:hover {
  background-image: linear-gradient(180deg, #2b3566, #5d4cb4, #2b3566);
  cursor: pointer;
}

.new-name-input {
  font-size: 24px;
  width: 400px;
  overflow: hidden;
  border: none;
  background-color: #2b3566;
  margin-left: 10px;
  color: #45d3c2;
  border-bottom: 2px solid #684aff;
}

.new-name-input:focus {
  outline: none;
}

.audio-button-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sound-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.current-sound {
  display: flex;
  padding-top: 5px;
  vertical-align: middle;
  margin-bottom: 10px;
  & .material-symbols-sharp {
    color: var(--oda-secondary-text-color);
    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
      color: var(--oda-main-text-color);
    }
  }
}

.current-sound:hover {
  cursor: pointer;
}

.sound-container .ant-slider {
  max-width: 250px;
  width: 100%;
}

.ant-slider .ant-slider-track {
  background-color: var(--oda-secondary-text-color);
}
.ant-slider .ant-slider-handle::after {
  background-color: white;
}

.audio-name {
  vertical-align: top;
  margin-right: 10px;
  font-weight: 900;
}

.new-alert.payment-alerts-previews-item .payment-alert-image-preview {
  border: none;
  background-color: #2b3566;
  color: #684aff;
}

.new-alert.payment-alerts-previews-item .payment-alert-image-preview:hover {
  background-image: linear-gradient(180deg, #0c122e, #2b3566);
}

.new-alert.payment-alerts-previews-item .material-symbols-sharp {
  font-size: 60px;
}

.alert-delete-button {
  position: absolute;
  top: 12px;
  right: 12px;
  padding-bottom: 1px;
  color: var(--oda-secondary-text-color);
}

.alert-delete-button .material-symbols-sharp {
  vertical-align: middle;
}

.alert-delete-button:hover {
  cursor: pointer;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-2px);
}
