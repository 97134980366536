.testbutton {
  width: 150px;
  margin-bottom: 10px;
}

.optionscontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-bottom: 20px;
}

.itembackcontainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.itembacklabel{
  flex: 0 0 auto;
  font-family: "Play", monospace;
}

.itembackuploadbutton{
  flex:  0 0 auto;
}

.backgroundimage{
  border: 1px solid #674afd;
  margin-top: 5px;
}

.addbuttoncontainer {
  width: 60%;
  text-align: center;
  padding-top: 20px;
}

.option {
  width: 60%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.option .widget-settings-value {
  width: unset;
}
