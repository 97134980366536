.container {
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 40px;
  align-items: center;
}

.name {
  position: relative;
  flex: 1 1;
  color: var(--oda-main-text-color);
  & span{
    font-size: 16px;
    color: var(--oda-main-text-color);
    margin-left: 5px;
    &[class*="material-symbols-sharp"]{
      color: rgba(251, 255, 241, 0.4);
    }
  }
}

.child {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  min-width: 200px;
  align-items: center;
  margin-right: 10px;
}

.helpmodal {
  position: absolute;
  top: 0;
}
