.adddalertbutton {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
