.current {
  background-color: var(--oda-control-background-color)!important;
  font-weight: 900!important;
  border-radius: 5px!important;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  & [class*="owner"] {
    color: white!important;
  }
  & [class*="song-title"] {
    width: calc(100% - 40px); /* 40px is the width of the youtube/vk logo */
  }
}

.listgroupitem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  &:hover{
    /* border: var(--oda-main-border)!important; */
    /* border-radius: 5px!important; */
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    background: var(--oda-main-background-color);
    box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }
}

.providericon {
  flex: 0 0 auto;
  height: 30px;
  width: 30px;
}
